<template>
  <div class="contents liveInfo" style="position: relative; height: calc(100vh - 50px);">
    <h1 style="height: 50px;line-height: 50px;margin-top:50px;">맵핑 목록</h1>
    <div class="liveRegister" style="padding: 0;">
      <div class="register" style="width: 22%;margin-right: 20px;">
        <div class="mainBox mapping-detail-info">
          <h2 class="bold">상세정보</h2>
          <ul>
            <li>
              <span>{{$t("mapping-data-title")}}</span>
              <h4>{{mappingInfo.title}}</h4>
            </li>
            <li>
              <span>{{ $t("mission-data-way-registrant") }}</span>
              <h4>{{ mappingInfo.userName? mappingInfo.userName : "-" }}</h4>
            </li>
            <li>
              <span>{{ $t("event-data-site-phone") }}</span>
              <h4>{{ mappingInfo.userPhone ? mappingInfo.userPhone : "-" }}</h4>
            </li>
            <li>
              <span>{{$t("mission-data-setting-altitude")}}</span>
              <h4>{{ mappingInfo.altitude ? mappingInfo.altitude : "-"}}m</h4>
            </li>
            <li>
              <span>{{$t("mission-data-setting-speed")}}</span>
              <h4>{{mappingInfo.autoFlightSpeed ? mappingInfo.autoFlightSpeed : "-"}}m/s</h4>
            </li>
            <li>
              <span>{{ $t("mission-data-way-dept") }}</span>
              <h4>{{ mappingInfo.userDepartmentName ? mappingInfo.userDepartmentName : "-" }}</h4>
            </li>
            <li>
              <span>{{$t("mission-data-setting-clear")}}</span>
              <h4>{{ mappingInfo.finishedActionType ? mappingInfo.finishedActionType : "-" }}</h4>
            </li>

            <li>
              <span>{{$t("mapping-data-camera")}}</span>
              <h4>{{ mappingInfo.info ? mappingInfo.info.cameraType : "-" }}</h4>
            </li>
            <li>
              <span>{{ $t("mapping-data-center") }}</span>
              <h4>{{ mappingInfo.lat ? mappingInfo.lat : "-" }}<br>{{ mappingInfo.lng ? mappingInfo.lng : "-" }}</h4>
            </li>
            <li>
              <span>{{$t("mapping-data-path-interval")}}</span>
              <h4>{{ mappingInfo.info || mappingInf.info.interval == 0 ? mappingInfo.info.interval : "-" }}</h4>
            </li>
            <li class="m-b-0">
              <span>{{ $t("mapping-data-path-angle") }}</span>
              <h4>{{ mappingInfo.info || mappingInfo.info.angle == 0 ? mappingInfo.info.angle : "-" }}</h4>
            </li>
            <li class="m-b-0">
              <span>{{$t("mapping-data-overlab")}}</span>
              <h4>{{ mappingInfo.info || mappingInfo.info.overLab == 0  ? mappingInfo.info.overLab : "-" }}</h4>
            </li>
          </ul>
        </div>
        <div class="tableWrap one mapping-detail-table">
          <h2>맵핑포인트 정보</h2>
          <div class="tableBox" style="height: 300px; overflow-y: auto;max-height: 300px;">
            <div class="mapping-detail-point-info">
              <div class="mapping-detail-point-title">
                <h2><span>1</span>번 포인트</h2>
                <ul>
                  <li>
                    <span>{{ $t("mission-data-lat") }}</span>
                    <h4>37.5447530171554</h4>
                  </li>
                  <li>
                    <span>{{ $t("mission-data-long") }}</span>
                    <h4>127.11864746338061</h4>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="buttonWrap" style="display: flex; margin-top: 16px; justify-content: center;">
          <button class="point medium" style="width: 32.3333%;height:40px; background:#0080FF;border-color:#0080FF;border-radius:5px;font-weight:400;"  @click="backToMissionList">
            {{ $t("btn-mission-list") }}
          </button>
          <button class="medium margin6" @click="handleUpdate" style="width: 32.3333%;height:40px; border-color:#ddd;background:#ffffff;color:#969696; font-weight:400;border-radius:5px;">
            {{ $t("btn-update") }}
          </button>
          <button class="medium margin6" @click="handleDelete" style="width: 32.3333%;height:40px; border-color:#ddd;background:#ffffff;color:#969696; font-weight:400;border-radius:5px;">
            {{ $t("btn-delete") }}
          </button>    
        </div>
      </div>
      <div class="map" style="width: calc(78% - 45px);height: calc(100% + 0px);position: absolute;right: 0;bottom: 0">
        <Map ref="map" style="width : 100%; height: 100%; z-index: 1;" :loadMapName="loadMap"/>
        <MapButtonComponent
            ref="buttonMap"
            />
      </div>
    </div>
  </div>
</template>

<style>
.mapping-detail-info{border-radius: 5px;padding: 15px; margin-bottom: 5px;}
.mapping-detail-info > h2{font-size: 1.4rem;margin-bottom: 10px;}
.mapping-detail-info > ul{display: flex;flex-direction: row;margin: 0;flex-wrap: wrap;align-items: flex-start;}
.mapping-detail-info > ul > li{display: flex;justify-content: space-between;align-items: flex-start;border: 0;padding: 0;margin: 0;margin-bottom: 8px;width: 50%;flex-direction: column;}
.mapping-detail-info > ul > li:last-child{margin-bottom: 0;}
.mapping-detail-info > ul > li > span{background: #eee;width: 80px;text-align: center;padding: 2px;font-size: 1.2rem;border-radius: 10px;color: #969696;margin-bottom: 5px;}
.mapping-detail-info > ul > li > h4{font-size: 1.2rem;font-weight: 500;color: #454545;}

.m-b-0{margin-bottom: 0 !important;}

.mapping-detail-table{padding: 15px;background: #fff;border-radius: 5px;}
.mapping-detail-table > h2{font-size: 1.4rem;margin-bottom: 15px;font-weight: bold;}
.mapping-detail-point-info{border: 1px solid #ddd;border-radius: 5px;padding: 10px 15px;margin-bottom: 5px;}
.mapping-detail-point-title{display: flex;justify-content: space-between;align-items: flex-start;}
.mapping-detail-point-title > h2{font-size: 1.4rem;font-weight: bold;line-height: 20px;}
.mapping-detail-point-title > h2 > span{color: #0080FF;}
.mapping-detail-point-title > ul{display: flex;flex-direction: column;width: 60%;}
.mapping-detail-point-title > ul > li{display: flex;justify-content: space-between;align-items: center;border: 0;padding: 0;margin-bottom: 10px;}
.mapping-detail-point-title > ul > li:nth-child(2){margin-bottom: 0px;}
.mapping-detail-point-title > ul > li > span{font-size: 1.2rem;color: #969696;}
.mapping-detail-point-title > ul > li > h4{font-size: 1.2rem;font-weight: 500;}

.remote-table{width: calc(45% + 0px);margin-left: 20px;position: absolute;z-index: 100;bottom: 30px;right: 10px;background: #ffffffeb;padding: 10px;border-radius: 5px;}

.mapping-detail-point-info:hover{border: 1px solid #6992F9;cursor: pointer;}
.mapping-detail-point-info.active{border: 1px solid #6992F9;}

.el-menu > li:nth-child(2){color: #fff !important;}
</style>

<script>
import moment from "moment";
import Map from "@/components/map/Map";

import { fetchEvent } from "@/api/event";
import { format } from "@/mixins/format";
import {fetchMissionInfo, fetchMissionDelete } from "@/api/mission";
import { fetchMissionLocationList} from "@/api/missionLocation";
import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";
import MapLineData from "@/components/map/data/MapLineData";
import MapPolygonData from "@/components/map/data/MapPolygonData";
import {mapState} from "vuex";
import {getDefaultMapZoomLevel} from "@/util/common";
import {fetchMappingInfo, fetchMappingDelete } from "@/api/missionMapping";
import { fetchMappingLocationList} from "@/api/missionMappingLocation";
import MapButtonComponent from "@/components/map/MapButtonComponent.vue";
fetchMappingInfo, fetchMappingDelete ,fetchMappingLocationList
export default {
  props: ["eventId","missionMappingId"],
  mixins: [format],
  components: { Map, MapButtonComponent},
  data() {
    return {
      moment: moment,
      missionId: "",
      eventInfo: {},
      mapHeight: 0,
      mappingInfo : {},
      mappingLocationList: [],
      mappingLocationTotal: 0,
      devPositionHeight : 122,
      typeChangeMapShowHeight: 0,
      selectEl : null,
      selectData : null,
      loadMap : 'kakao',
      altitude: null,
      finishedActionType : null,

       cameraTypeList : [{
        id : "DJIAir2SCamera",
        fov  : 88.0,  //카메라가 바라보는 화각 
        focalLength : 8.38,
        manufacture : "DJI",
        model : "FC3411",
        aspectRatio : {width : 5472, height :  3078},
        sensor : { width : 13.2, height :  8.8},
      },
      {
        id : "DJIMini2Camera",
        fov  : 83.0,  //카메라가 바라보는 화각 
        focalLength : 4.49,
        manufacture : "DJI",
        model : "FC3411",
        aspectRatio : {width : 4000, height :  2250},
        sensor : { width : 6.3, height :  4.7},
      }],
    };
  },
  mounted() {
   // this.$refs.map.isShowLine = true;
    this.getEvent();
    this.getMappingInfo();
    this.initButtonMap()
  },
  created() {
    this.eventId = this.$route.query.eventId;
    this.missionId = this.$route.query.missionId;

    this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 15 });

    this.mapHeight = window.innerHeight - this.devPositionHeight;
    this.typeChangeMapShowHeight = this.mapHeight * 0.5625;
    window.addEventListener('resize', this.handleResize);
  },
  watch : {
    currentLang(value){
      this.initMapData(value);
    },
  },
  computed: {
    ...mapState({
      currentLang: state => state.store.currentLang,
    }),
  },
  methods: {
    initMapData(value){
      if(value == 'en') {
        this.loadMap = 'google'
      } else {
        this.loadMap = 'kakao'
      }

      this.$nextTick(function(){ 
        if(this.eventInfo != null){
           this.getMappingLocationList();
          this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
          if (this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
            this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
          } else {
            //this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
          }
        }
      })
    },

    getGimbalTypeShow(findId){
      const index = this.gimbalTypeList.findIndex((data) => data.id == findId)
      if(index != -1) {
        return this.gimbalTypeList[index].show;
      } else {
        return "None";
      }
    },
    initButtonMap() {
      this.$refs.buttonMap.showMapTypeChange()
    },
    handleSelectEl(data) {
      var id = data.index;
      if(this.selectEl == id) {
        this.selectEl = null;
        this.selectData = null;
      } else {
        this.selectEl = id;
        this.selectData = data;
      }

      if(this.selectData != null) {
        this.$refs.map.onLngLatToMoveCenter(this.selectData.lng,  this.selectData.lat)
      }
    },
    getMappingInfo(){
      fetchMissionInfo(this.missionId).then((res) => {
        if (res.data.result == 0) {
          this.mappingInfo = res.data.data;
        }
        this.initMapData(this.currentLang);
      }).catch(err =>{
        console.error(err)
      })
      // fetchMappingInfo(this.mappingId).then((res) => {
      //   if (res.data.result == 0) {
      //     this.mappingInfo = res.data.data;
      //   }
      //   this.initMapData(this.currentLang);
      // }).catch(err =>{
      //   console.error(err)
      // })
    },
    getMappingLocationList(){
      let params = {
        eventId : this.eventId,
        missionId : this.missionId
      };
      var point = {lat : this.mappingInfo.lat , lng : this.mappingInfo.lng }
      fetchMissionLocationList(params).then((res) => {
        if (res.data.result == 0) {
          this.mappingLocationTotal = res.data.data.totalElements
          this.mappingLocationList = res.data.data.content

          let mapDataType = MapDataType.ID_MAPPING

          let hiddenPolygonData = new MapPolygonData(); //mapping을 위한 도형 
          hiddenPolygonData.mapDataType = mapDataType
          hiddenPolygonData.setLatLng(point)
          hiddenPolygonData.setPolygon(MapDataType.ID_MAPPING_HIDDEN_POLYGON)
          hiddenPolygonData.posList =  res.data.data.content
          hiddenPolygonData.line = hiddenPolygonData.posList

          let mapPolygonData = new MapPolygonData();
          mapPolygonData.mapDataType = mapDataType
          mapPolygonData.setLatLng(point)
          mapPolygonData.posList =  res.data.data.content
          mapPolygonData.setPolygon(MapDataType.ID_MAPPING_SQURE)
          mapPolygonData.line = mapPolygonData.posList
          
          let mapCircle = new MapPolygonData();
          mapCircle.setLatLng(point)
          mapCircle.setPolygon(MapDataType.ID_MAPPING_CIRCLE)
          mapCircle.fillOpacity = 0
          mapCircle.strokeOpacity = 0

          let mapPolyLine = new MapLineData();
          mapPolyLine.color  = `#31A2FF`
          mapPolyLine.line = mapPolygonData.posList 
          mapPolyLine.strokeWeight = 10
          mapPolyLine.strokeOpacity = 1
          mapPolyLine.strokeStyle = 'solid'

          let mapMarker = new MapMarkerData();
          mapMarker.posList = mapPolygonData.posList
          mapMarker.mapDataType = MapDataType.ID_MAPPING_MARKER
         
          this.$refs.map.onCreateMappingCircle(mapCircle);
          this.$refs.map.onCreateMappingPolygon(hiddenPolygonData,MapDataType.ID_MAPPING_HIDDEN_POLYGON );
          this.$refs.map.onCreateMappingPolygon(mapPolygonData,MapDataType.ID_MAPPING_SQURE );
          this.$refs.map.onCreateMappingMarker(mapMarker);
          this.$refs.map.onCreateMappingPolyLine(mapPolyLine, MapDataType.ID_MAPPING_LINE)
          this.settingMappingData()
          this.$refs.map.onLngLatToMoveCenter(this.mappingInfo.lng, this.mappingInfo.lat)
        }
      }).catch(err =>{
        console.error(err)
      })

      // fetchMappingLocationList(params).then((res) => {
      //   if (res.data.result == 0) {
      //     this.mappingLocationTotal = res.data.data.totalElements
      //     this.mappingLocationList = res.data.data.content

      //     let mapDataType = MapDataType.ID_MAPPING

      //     let hiddenPolygonData = new MapPolygonData(); //mapping을 위한 도형 
      //     hiddenPolygonData.mapDataType = mapDataType
      //     hiddenPolygonData.setLatLng(point)
      //     hiddenPolygonData.setPolygon(MapDataType.ID_MAPPING_HIDDEN_POLYGON)
      //     hiddenPolygonData.posList =  res.data.data.content
      //     hiddenPolygonData.line = hiddenPolygonData.posList

      //     let mapPolygonData = new MapPolygonData();
      //     mapPolygonData.mapDataType = mapDataType
      //     mapPolygonData.setLatLng(point)
      //     mapPolygonData.posList =  res.data.data.content
      //     mapPolygonData.setPolygon(MapDataType.ID_MAPPING_SQURE)
      //     mapPolygonData.line = mapPolygonData.posList
          
      //     let mapCircle = new MapPolygonData();
      //     mapCircle.setLatLng(point)
      //     mapCircle.setPolygon(MapDataType.ID_MAPPING_CIRCLE)
        

      //     let mapPolyLine = new MapLineData();
      //     mapPolyLine.color  = `#31A2FF`
      //     mapPolyLine.line = mapPolygonData.posList 
      //     mapPolyLine.strokeWeight = 10
      //     mapPolyLine.strokeOpacity = 1
      //     mapPolyLine.strokeStyle = 'solid'

      //     let mapMarker = new MapMarkerData();
      //     mapMarker.posList = mapPolygonData.posList
      //     mapMarker.mapDataType = MapDataType.ID_MAPPING_MARKER
         
      //     this.$refs.map.onCreateMappingCircle(mapCircle);
      //     this.$refs.map.onCreateMappingPolygon(hiddenPolygonData,MapDataType.ID_MAPPING_HIDDEN_POLYGON );
      //     this.$refs.map.onCreateMappingPolygon(mapPolygonData,MapDataType.ID_MAPPING_SQURE );
      //     this.$refs.map.onCreateMappingMarker(mapMarker);
      //     this.$refs.map.onCreateMappingPolyLine(mapPolyLine, MapDataType.ID_MAPPING_LINE)
      //     this.settingMappingData()
      //     this.$refs.map.onLngLatToMoveCenter(this.mappingInfo.lng, this.mappingInfo.lat)
      //   }
      // }).catch(err =>{
      //   console.error(err)
      // })
    },
    
    createMapLine(id, locationGroup){
      let mapDataType = MapDataType.ID_LIVE
      var mapLineData = new MapLineData();
      mapLineData.id = id;
      mapLineData.locationGroup = locationGroup;
      mapLineData.isMain = true;
      mapLineData.isShow = true;
      if(this.$refs.map.onIsLine(mapDataType, id)) {
        this.$refs.map.onModifyLineAddAt(mapDataType, mapLineData);
      } else {
        this.$refs.map.onCreateLine(mapDataType, mapLineData);
      }
    },
    settingMappingData(){
      if(this.mappingInfo.info != null){
        var findIndex = this.getCameraListId(this.mappingInfo.info.cameraType);
        var camera = JSON.parse(JSON.stringify(this.cameraTypeList[findIndex]));
        camera.focalLength = (camera.focalLength / 10).toFixed(3);
        camera.sensor.height = (camera.sensor.height / 10).toFixed(3);
        camera.sensor.width = (camera.sensor.width / 10).toFixed(3);
        this.$refs.map.sendCameraType(camera);
        this.$refs.map.sendInterval(Number(this.mappingInfo.info.interval));
        this.$refs.map.sendAltitude(Number(this.mappingInfo.altitude))
        this.$refs.map.sendAngle(Number(this.mappingInfo.info.angle));
        this.$refs.map.onInitMapping();
       
      }
    },
    getCameraListId(id){
      return this.cameraTypeList.findIndex(item => {return item.id == id})
    },
    getEvent() {
      if(this.eventId == undefined) {
        return;
      }
      fetchEvent(this.eventId).then((res) => {
        if (res.data.result == 0) {
          this.eventInfo = res.data.data;
          this.memo = this.eventInfo.memo;
          if(this.eventInfo.status == "Close"){
            const elapsedMSec = this.eventInfo.closeDate.getTime() - this.eventInfo.openDate.getTime()
            this.elapsedDay = elapsedMSec / 1000 / 60 / 60 / 24;
          }
        }
      }).catch(err =>{
        console.error(err)
      });
    },
    handleDelete() {
      let result = confirm(this.$t("alert-message-delete-mission-detail"));
      if (result == false) {
        return;
      } else {
        fetchMissionDelete(this.missionId).then((res) => {
          if (res.data.result == 0) {
            alert(this.$t("alert-message-delete"));
            this.$emit("back-list")
            this.$router.push("Mapping")
          }
        }).catch(err =>{
          console.error(err)
        });

        // fetchMappingDelete(this.mappingId).then((res) => {
        //   if (res.data.result == 0) {
        //     alert(this.$t("alert-message-delete"));
        //     this.$emit("back-list")
        //     this.$router.push("Mapping")
        //   }
        // }).catch(err =>{
        //   console.error(err)
        // });
      }
    },
    handleUpdate() {
      let result = confirm(this.$t("alert-message-update-mission-detail"));
      if (result == false) {
        return;
      } else {
        this.$router.push({ name: "MappingUpdate" , query: {eventId : this.eventInfo.eventId, missionId: this.missionId }})

      }
    },
    //페이지 리사이즈
    handleResize(){
      this.mapHeight = window.innerHeight - this.devPositionHeight;
      this.typeChangeMapShowHeight = this.mapHeight * 0.5625;
    },
    backToMissionList(){
      this.$router.go(-1);
    },
  },
  // 삭제는 SDFile delete fileId 사용
};
</script>
